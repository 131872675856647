<template>
  <div>
    <b-card>
      <b-button-toolbar justify>
        <div />
        <div>
          <b-button
            v-b-tooltip.hover="'Refresh'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="LoadContainerStatus()"
          >
            <font-awesome-icon
              :icon="['fas', 'sync']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="clearFilter()"
          ><font-awesome-icon
             :icon="['fas', 'filter']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Clear</span></b-button>
        </div>
      </b-button-toolbar>
      <AgGridNoEdits :aggrid="aggrid" />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import AgGridNoEdits from '@/layouts/components/AgGridNoEdits.vue'

export default {
  components: {
    AgGridNoEdits,
  },
  data() {
    return {
      aggrid: [],
    }
  },
  beforeMount() {
    this.aggrid = this.InitGridInstance('ContainerStatusReport')
    this.aggrid.columnDefs = [
      {
        headerName: 'Status', field: 'status', minWidth: 120, filter: 'agSetColumnFilter',
      },
      { headerName: 'Container #', field: 'containerNumber', minWidth: 100 },
      {
        headerName: 'Container Size', field: 'containerSize', minWidth: 100, filter: 'agSetColumnFilter',
      },
      { headerName: 'MBL', field: 'mbl', minWidth: 100 },
      { headerName: 'HBL', field: 'hbl', minWidth: 100 },
      { headerName: 'CBM', field: 'cbm', minWidth: 100 },
      { headerName: 'Weight', field: 'weight', minWidth: 100 },
      { headerName: 'Pallet', field: 'pallet', minWidth: 100 },
      { headerName: 'PCS', field: 'pcs', minWidth: 100 },
      { headerName: 'Consignee', field: 'consignee', minWidth: 100 },
      { headerName: 'Shipper', field: 'seller', minWidth: 100 },
      { headerName: 'Shipment CBM', field: 'totalCBM', minWidth: 100 },
      { headerName: 'Shipment Weight', field: 'totalWeight', minWidth: 100 },
      { headerName: 'Shipment Pallet', field: 'totalPallet', minWidth: 100 },
      { headerName: 'Per diem Free Time', field: 'perdiemFreeTime', minWidth: 100 },
      {
        headerName: 'Last Free',
        field: 'lastFreeDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Empty Dispatch',
        field: 'dispatchDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Gate In',
        field: 'inGateDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Gate Out',
        field: 'outGateDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Discharged',
        field: 'dischargedDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Appointment',
        field: 'appointmentDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Delivered',
        field: 'deliveredDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Empty Return',
        field: 'emptyReturnDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },

    ]
    this.aggrid.rowData = []
  },
  mounted() {
    Promise.all([this.LoadingStart(), this.LoadContainerStatus()])
      .then(() => {
        this.LoadingEnd()
      })
  },
  methods: {
    LoadContainerStatus() {
      axios.get('/booking/reports/containers/status')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.ResetColumns()
        })
    },
  },
}
</script>
